import request from '@/utils/request'


// 查询文件夹列表
export function listFolder(query) {
  return request({
    url: '/folder/folder/list',
    method: 'get',
    params: query
  })
}

// 查询文件夹分页
export function pageFolder(query) {
  return request({
    url: '/folder/folder/page',
    method: 'get',
    params: query
  })
}

// 查询文件夹详细
export function getFolder(data) {
  return request({
    url: '/folder/folder/detail',
    method: 'get',
    params: data
  })
}

// 新增文件夹
export function addFolder(data) {
  return request({
    url: '/folder/folder/add',
    method: 'post',
    data: data
  })
}

// 修改文件夹
export function updateFolder(data) {
  return request({
    url: '/folder/folder/edit',
    method: 'post',
    data: data
  })
}

// 删除文件夹
export function delFolder(data) {
  return request({
    url: '/folder/folder/delete',
    method: 'post',
    data: data
  })
}
